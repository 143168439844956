/**
 * @category  sc
 * @package   Product_Test
 * @author    Ali Eltahan <info@alieltahan.com>
 */

.main {
    &__wrapper {
        min-height: 85vh;
        display: flex;
        flex-direction: column;
    }
}